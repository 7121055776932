<template>
  <div class="csn-mmgc">
    <section class="section-menu-buttons">
      <div class="row-fluid">
        <div class="col-md-6 csn-mm-header">
          <a @click="returnToMainMenu">
            <SlideArrow :isBack="true" />
          </a>
        </div>
        <div class="col-md-6 text-right csn-mm-header">
          <a class="close-btn" @click="TOGGLE_MAIN_MENU">
            <CloseIcon />
          </a>
        </div>
      </div>
    </section>

    <Message :type="messageType" :message="message" />
    <Loader v-if="isPending" />
    <div v-else class="main-menu csn-mm-list csn-mmgc-list">
      <ul>
        <li class="csn-mm-list-li">
          <Route
            :to="{ name: routeName.freePlay }"
            class="csn-mm-list-route csn-mm-list-a"
          >
            <span
              v-if="isCasinoPlatin"
              class="casino-icon casino-icon-lobby"
            ></span>
            <FivePlayNowIcon v-else />
            <span>{{ t('play_now') }}</span>
          </Route>
        </li>
        <li class="csn-mm-list-li">
          <Route
            :to="{ name: routeName.newGames }"
            class="csn-mm-list-route csn-mm-list-a"
          >
            <span
              v-if="isCasinoPlatin"
              class="casino-icon casino-icon-new-games"
            ></span>
            <FiveNewGamesIcon v-else />
            <span>{{ t('new_games') }}</span>
          </Route>
        </li>
        <li class="csn-mm-list-li">
          <Route
            :to="{ name: routeName.topGames }"
            class="csn-mm-list-route csn-mm-list-a"
          >
            <span
              v-if="isCasinoPlatin"
              class="casino-icon casino-icon-top-games"
            ></span>
            <TopGamesIcon v-else />
            <span>{{ t('top_games') }}</span>
          </Route>
        </li>
        <li
          v-for="{ id, urlSlug, image, name, gameCount } in categoryList"
          :key="id"
          class="csn-mm-list-li"
        >
          <Route
            :to="{ name: routeName.gameCategory, params: { slug: urlSlug } }"
            class="csn-mm-list-route csn-mm-list-a"
          >
            <img :src="image" class="csn-mmgc-list-image" />

            <span class="csn-mmgc-list-name">{{ name }}</span>
            <span>{{ gameCount }}</span>
          </Route>
        </li>
        <li class="csn-breadcrumb-search csn-mm-list-li">
          <a @click="openSearchMenu" class="csn-mm-list-route csn-mm-list-a">
            <span
              v-if="isCasinoPlatin"
              class="casino-icon casino-icon-search"
            ></span>
            <SearchIcon v-else />
            <div>{{ t('search') }}</div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import FivePlayNowIcon from '@/components/svg/FivePlayNowIcon'
import FiveNewGamesIcon from '@/components/svg/FiveNewGamesIcon'
import TopGamesIcon from '@/components/svg/TopGamesIcon'
import SearchIcon from '@/components/svg/SearchIcon'

import {
  MAIN_MENU_GAME_CATEGORY,
  RouteName,
  Module,
  CASINO_PLATIN,
  MessageTypeEnum,
  EMPTY_STRING,
  TOGGLE_SEARCH_MENU,
  TOGGLE_MAIN_MENU,
  SHOWS_MAIN_MENU,
  ResponseState,
  URL_SLUG,
  MAIN_MENU,
  BREADCRUMBS,
  TOGGLE_MAIN_MENU_COMPONENT,
} from '@/constants'
import { indexBy } from '@/helpers'
import { GameApi } from '@/api'

export default {
  name: MAIN_MENU_GAME_CATEGORY,
  components: {
    Route: () => import('@/components/Route'),
    Loader: () => import('@/components/Loader'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
    Message: () => import('@/components/atoms/Message'),
    FivePlayNowIcon,
    FiveNewGamesIcon,
    TopGamesIcon,
    SearchIcon,
  },
  data: () => ({
    isPending: false,
    categoryList: null,
    currentRouteSlug: null,
    message: '',
  }),
  computed: {
    ...mapState(Module.MAIN, [SHOWS_MAIN_MENU]),
    isCasinoPlatin: () => process.env.VUE_APP_THEME === CASINO_PLATIN,
    messageType: () => MessageTypeEnum.FAILURE,
    routeName: () => ({
      freePlay: RouteName.GAME_FREE_PLAY,
      gameCategory: RouteName.GAME_CATEGORY,
      newGames: RouteName.GAME_NEW_GAMES,
      topGames: RouteName.GAME_TOP_GAMES,
    }),
    t() {
      return this.$createComponentTranslator(BREADCRUMBS)
    },
  },

  methods: {
    ...mapMutations(Module.MAIN, {
      TOGGLE_SEARCH_MENU,
      TOGGLE_MAIN_MENU,
      TOGGLE_MAIN_MENU_COMPONENT,
    }),
    openSearchMenu() {
      this.SHOWS_MAIN_MENU && this.TOGGLE_MAIN_MENU()
      this.TOGGLE_SEARCH_MENU(true)
    },
    returnToMainMenu() {
      this.TOGGLE_MAIN_MENU_COMPONENT({ value: MAIN_MENU })
    },
  },
  watch: {
    $route: {
      handler({ params }) {
        this.currentRouteSlug = params.slug || EMPTY_STRING
      },
      immediate: true,
    },
  },
  async mounted() {
    this.isPending = true

    try {
      const { state, data, error } = await GameApi.getGameCategoryList()

      state === ResponseState.ERROR
        ? (this.message = error)
        : (this.categoryList = indexBy(URL_SLUG)(data))
    } catch (e) {
      console.error(e)
    }

    this.isPending = false
  },
}
</script>

<style scoped></style>
